import React from 'react';
import "./App.css";
import Header from './components/header/Header';
// import Home from './components/home/Home';
// import About from './components/about/About';
// import Skill from './components/Skills/Skills';
// import Services from './components/services/Services';
// import Qualification from './components/qualification/Qualification';
// import Contact from './components/contact/Contact';
// import Footer from './components/footer/Footer';
// import Portfolio from './components/portfolio/Portfolio';
// import ScrollUp from './components/scrollup/ScrollUp';

const App = () => {
  return (
    <>
    {/* <Header/> */}

    <main className='main'>
      <Header/>
      {/* <Home/>
      <About/>
      <Skill/>
      <Services/>
      <Qualification/>
      <Portfolio/>
      <Contact/> */}
    </main>
    {/* <Footer/> */}
    {/* <ScrollUp/> */}
    </>

  )
}

export default App
